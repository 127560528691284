import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAsWE_4iNywWWAxm-W1w7uILn-TLIgfqp8",
  authDomain: "sib-signup-system.firebaseapp.com",
  projectId: "sib-signup-system",
  storageBucket: "sib-signup-system.appspot.com",
  messagingSenderId: "4629007172",
  appId: "1:4629007172:web:bcc455e6b32cd38280e511"
};
  //init
  firebase.initializeApp(firebaseConfig)

  //init services
  const projectFirestore = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()

  //timestamp
  const timestamp = firebase.firestore.Timestamp

  export {projectFirestore, projectAuth, projectStorage,timestamp}