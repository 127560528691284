import { useEffect, useState} from 'react'
import { useForgotPass } from '../../hooks/useForgotPass'
import Timer  from '../../components/Timer';

//styles
import './ForgotPassword.css'

export default function ForgotPassword() {
	const [email, setEmail] = useState('')
	const {sendForgotPass, isPending, error} = useForgotPass()
	const [isSent, setSent] = useState(false)
	const [longwait, setLongwait] = useState(false)
	const [toggle, setToggle] = useState(false)
	const [timer, setTimer] = useState(new Date().getSeconds())

	const handleSubmit = (e) => {

		e.preventDefault()
		sendForgotPass(email)

		if(!isSent && !longwait){
			setTimer(new Date().setSeconds(new Date().getSeconds() + 120))
			setSent(true)
			setLongwait(true)
			setTimeout(() => {
				setLongwait(false)
			}, 120000 )
		}

		else{

		
		
		setTimer(new Date().setSeconds(new Date().getSeconds() + 120))
		setLongwait(true)
		setTimeout(() => {
		  setLongwait(false)
		}, 120000) }

		setEmail('')
		
	  }
    

	useEffect( () =>{
		const longwait = window.localStorage.getItem('FORGOT_PASS_LONGWAIT')
		if (longwait !== null){
			setLongwait(JSON.parse(longwait))
		}
	}, [])

	useEffect( () =>{
		const timer = window.localStorage.getItem('FORGOT_PASS_TIMER') 
		if (timer !== null){
			setTimer(JSON.parse(timer))
		}
	}, [])


	useEffect( () =>{
		window.localStorage.setItem('FORGOT_PASS_LONGWAIT', JSON.stringify(longwait))
	}, [longwait])

	useEffect( () =>{
		window.localStorage.setItem('FORGOT_PASS_TIMER', JSON.stringify(timer))
	}, [timer])


	const handleDelete = () => {
		localStorage.removeItem('FORGOT_PASS_TIMER');
    	localStorage.removeItem('FORGOT_PASS_LONGWAIT');
		setSent(true)
    	setLongwait(false)
		setToggle(true)
	}
    

	return (
			<div>
			<form onSubmit={handleSubmit} className="auth-form">
			<h2>Reset Password</h2>
			<br></br>
			<p>Reset your password by providing your account email below.</p>
			<label>
			<span>Email:</span>
			<input
        required 
        type="email" 
        onChange={(e) => setEmail(e.target.value)} 
        value={email}
			/>
			</label>

		{(!error && !isPending && !isSent && !longwait)&& <button className="btn" style={{float: "right", padding: " 8px 25px"}}>Send</button>}
		{(!error && isSent && !longwait )&& <button className="btn" style={{float: "right", padding: " 8px 25px"}}>Resend</button>}
		{(!error && !isPending && isSent && longwait)&& <button className="btn" style={{float: "right", padding: " 8px 25px"}} disabled><Timer expiryTimestamp={timer} onExpire={handleDelete} /></button>}
		{(!error && !isPending && !isSent && longwait)&& <button className="btn" style={{float: "right", padding: " 8px 25px"}} disabled><Timer expiryTimestamp={timer} onExpire={handleDelete} /></button>}
			
			<br></br>
			<br></br>
			{(!error &&(toggle || longwait)) && <p style={{color: "#3bdd92"}}>We've sent an email to your inbox. Remember to check spam!</p>}
		{error && <div className='error'>{error}</div>}
		</form>
			
		
		</div>
	)
}
