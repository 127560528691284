import React from 'react';
import { useTimer } from 'react-timer-hook';

export default function Timer({ expiryTimestamp, onExpire }) {
    const {
      seconds,
      minutes,
    } = useTimer({ expiryTimestamp, onExpire });
  
    function padWithZeros(number, minLength) {
        const numberString = number.toString();
        if(numberString.length >= minLength) return numberString;
        return "0".repeat(minLength - numberString.length) +  numberString;
    }

    return (
      <div style={{textAlign: 'center'}}>
        <div >
          <span>{padWithZeros(minutes, 2)}</span>:<span>{padWithZeros(seconds, 2)}</span>
        </div>
      </div>
    );
  }