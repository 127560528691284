import Logo from '../assets/logo.png'
import LogoNoText from '../assets/logo-no-text.png'
import { Link } from 'react-router-dom'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'

//styles
import './Navbar.css'

export default function Navbar() {
	const {logout, error, isPending} = useLogout()
	const {user} = useAuthContext()

	return (
			<div className='navbar'>
			<div className='desktop'>
			<ul>
			<li className='logo'>
			<a href='https://stockholminnovationbootcamp.com'><img src={Logo} alt="logo"/></a>
			</li>

		
		{!user && (
			
				<>
				<li><Link to="/privacy" style={{margin: "0 30px 0 0"}} > Privacy Policy </Link></li>
				<li><Link to="" style={{margin: "0 30px 0 0"}} onClick={() => window.location = 'mailto:hello@stockholminnovationbootcamp.com'}> Contact us </Link></li>
				<li><Link  to="/login" className='btn'>Login</Link></li>
				<li><Link  to="/Signup" className='btn'>Signup</Link></li>
				</>
		)}

		{ user && (
				<>
				<li><Link to="" style={{margin: "0 30px 0 0"}} onClick={() => window.location = 'mailto:hello@stockholminnovationbootcamp.com'}> Contact us </Link></li>
				<li>
				{!isPending && <button className='btn' onClick={logout}>Logout</button>}
			{isPending && <button className='btn' disabled>Logging out ...</button>}
			</li>
				</>
		)}
		</ul>
			</div>
			<div className='mobile'>
			<ul>
			<li className='logo-no-text'>
			<a href='https://stockholminnovationbootcamp.com'><img src={LogoNoText} alt="logo"/></a>
			</li>

		{!user && (
			
				<>
				<li><Link  to="/login" className='btn-mobile'>Login</Link></li>
				<li><Link  to="/Signup" className='btn-mobile'>Signup</Link></li>
				</>
		)}

		{ user && (
				<>
				<Link to="" style={{float: "right"}} onClick={() => window.location = 'mailto:hello@stockholminnovationbootcamp.com'}> Contact us </Link>
				<li>
				{!isPending && <button className='btn-mobile' onClick={logout}>Logout</button>}
			{isPending && <button className='btn-mobile' disabled>Logging out ...</button>}
			</li>
				</>
		)}
		</ul>
			{!user && (<Link to="" style={{float: "right"}} onClick={() => window.location = 'mailto:hello@stockholminnovationbootcamp.com'}> Contact us </Link>)}
		{!user && (<Link to="/privacy" style={{float: "right"}}> Privacy Policy </Link>)}
		

		</div>

		</div>
	)
}
