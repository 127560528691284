import { useParams } from "react-router-dom"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useFirestore } from "../../hooks/useFirestore"
import { useHistory } from 'react-router-dom'
import { projectFirestore } from "../../firebase/config"
import { useState } from "react"
import { useEffect } from "react"
import { Link } from 'react-router-dom'

// styles
import './Project.css'

export default function Project() {
	const { user } = useAuthContext()
	const { id } = useParams()
	const {updateDocument} = useFirestore('2023/users/users')
	const [fieldOfStudy, setFieldOfStudy] = useState('')
	const [hobbies, setHobbies] = useState('')
	const [question1, setQuestion1] = useState('')
	const [question2, setQuestion2] = useState('')
	const [question3, setQuestion3] = useState('')
	const [question4, setQuestion4] = useState('')
	const [participate, setParticipate] = useState('')
	const [event, setEvent] = useState(null)
	const [erro, setErro] = useState(null)
	const [document, setDocument] = useState(null)
	const [error, setError] = useState(null)
	const [isPressedRegister, setIsPressed] = useState(false)
	const [isPressedUnegister, setIsPressedUnegister] = useState(false)
	const [cancel, setCancel] = useState(false)

	useEffect(() => {
		const ref = projectFirestore.collection('2023/events/events').doc(id)

		const unsubscribe = ref.onSnapshot(snapshot => {
			// need to make sure the doc exists & has data
			if(snapshot.data()) {
				setEvent({...snapshot.data(), id: snapshot.id})
				setErro(null)
			}
			else {
				setErro('No such document exists')
			}
		}, err => {
			console.log(err.message)
			setErro('failed to get document')
		})

		// unsubscribe on unmount
		return () => unsubscribe()

	}, ['2023/events/events', id])

	useEffect(() => {
		const ref = projectFirestore.collection('2023/users/users').doc(user.uid)

		const unsubscribe = ref.onSnapshot(snapshot => {
			// need to make sure the doc exists & has data
			if(snapshot.data()) {
				setDocument({...snapshot.data(), id: snapshot.id})
				setError(null)
			}
			else {
				setError('No such document exists')
			}
		}, err => {
			console.log(err.message)
			setError('failed to get document')
		})

		// unsubscribe on unmount
		return () => unsubscribe()

	}, ['2023/users/users', user.uid])


  if (error) {
    return <div className="error">{error}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  } 

	const handleRegister= () => {
		setIsPressed(true)
		setCancel(false)

	}

	const handleUnregister= () => {
		setIsPressedUnegister(true)
    }

	const handleDelete= () => {
		updateDocument(user.uid, {registered: false })
		setIsPressedUnegister(false)
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		updateDocument(user.uid, {registered: true, hobbies: hobbies, fieldOfStudy: fieldOfStudy, answers: [question1, question2, question3, question4], participate })
		setIsPressed(false)
		
	}

	const handleCancel= () => {
		setCancel(true)
		setIsPressed(false)
		setIsPressedUnegister(false)

	}

	if (erro) {
		return <div className="error">{error}</div>
	}
	if (!event) {
		return <div className="loading">Loading...</div>
	}
	const eventInfo = String(event.info).replace(/\\n/g, "<br/><br/>")

	return (
			<div>
			<div className="desktop">
			<div>
			<div className="project-summary">
			  <h3>{event.name}</h3>
			<h2>Event date: <i className="lime">{event.date_start.toDate().toDateString()} - {event.date_end.toDate().toDateString()}</i></h2>
			  <p className="scroll-down">Scroll down to register</p>
			{ (document.registered) && 
			  (<h4>You are registered!</h4>)}

			<div className='partner-bg'> </div>
			<p className="deadline">Registration deadline {''}
			<span style={{color: '#3bdd92'}}>{event.deadline.toDate().toDateString()} </span>{' '}</p>
			  <p className="info" dangerouslySetInnerHTML={{__html: eventInfo}}></p>
			<div className="info-rules">
			  <b className="note" id="desktopNote">NOTE: &nbsp; &nbsp;</b>
			  This is a free event for students. By registering to the event you agree to our rules:
			  <ol>
				<li>One can not register after the registration deadline. This is set for {event.deadline.toDate().toDateString()}.</li>
				<li>One can not unregister after the un-register deadline. This is set for {event.unregister_deadline.toDate().toDateString()}.</li>
				<li>Participation if free of charge - we are all poor students here. We will, however, enforce a 500 SEK no-show fee for those who sign up, get accepted and do not show up to the event, hindering someone else from joining instead.</li>
			  </ol>
			</div>

			</div>

			<br></br>

			{ event.closed && (<p style={{color: "rgb(253, 2, 2)", margin: "0 0 20px 0"}}>Registration period is closed!</p>)   }
          

		{( !(event.closed) && !(document.registered) && (!isPressedRegister)  && 
           (<button className="btn-register" style={{margin: "0 0 100px 0", float: "right"}} onClick={handleRegister}>Register</button>))} 

		{ ( !(document.registered) && isPressedRegister && !cancel) &&
          (<form onSubmit={handleSubmit} className="auth-form">
		   <h2>Registration info</h2>
		   <label>
			 <span>Field of study:</span>
           <input
			 required
           type="text" 
           onChange={(e) => setFieldOfStudy(e.target.value)} 
           value={fieldOfStudy}
           />
		   </label>
		   <label>
           <span>Hobbies and other interests?</span>
           <textarea
           required
			 type="text"
           onChange={(e) => setHobbies(e.target.value)} 
           value={hobbies}
           />
		   </label>
		   <label>
           <span>What does innovation mean to you?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setQuestion1(e.target.value)} 
           value={question1}
           />
		   </label>
		   <label>
           <span>What do you expect from Stockholm Innovation Bootcamp?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setQuestion2(e.target.value)} 
           value={question2}
           />
		   </label>


		   <label>
           <span>How did you find about the Stockholm Innovation Bootcamp?</span>
           <input
           required
           type="text" 
           onChange={(e) => setQuestion3(e.target.value)} 
           value={question3}
           />
		   </label>

		   <label>
           <span>Anything else you want to share?</span>
           <input
           required
           type="text" 
           onChange={(e) => setQuestion4(e.target.value)} 
           value={question4}
           />
		   </label>


		   <label>
           <span>Can you participate at the event in Stockholm between 27-29 of January 2023?</span>
		   <input required className="radio-buttons-participate" type="radio" value="yes" onChange={(e) => setParticipate(e.target.value)}  name="participate" /> Yes
		   <br />
           <input required className="radio-buttons-participate" type="radio" value="no" onChange={(e) => setParticipate(e.target.value)} name="participate" /> No
		   </label>

		   <label>
		   <input required className="radio-buttons-participate" type="checkbox" value="yes" onChange={(e) => setParticipate(e.target.value)}  name="participate" /> &nbsp; I have read and agreed to the <Link to="/privacy" style={{ color: "#3bdd92"}} > Privacy Policy </Link>and <Link to="#desktopNote" style={{color: "#3bdd92"}} >Terms</Link>
		   <br />
		   </label>

		   <button className="btn-register">Register</button>
		   
		   <button className="btn-cancel" onClick={handleCancel}>Cancel</button>
		   
		   </form>)}

        { (document.registered) && 
          (<p className='congrats-text'>Congrats! you have signed up for this year's bootcamp</p>)} 

          <br></br>

          { (!event.unbookable && document.registered && !isPressedUnegister) && 
          (<button className="btn-delete" style={{float: "right", margin: "0 0 50px 0"}} onClick={handleUnregister}>Unregister</button>)} 

      { isPressedUnegister &&
          (<form onSubmit={handleDelete} className="auth-form">
          <h2>Are you sure you want to unregister?</h2>
<br/>
      
          <button className="btn">Yes</button>
 
          <button className="btn-cancel" onClick={handleCancel}>Cancel</button>
     
          </form>)}

    </div>
    </div>
			<div className="mobile">
			<div>
			  <div className="project-summary">

				<h3>{event.name}</h3>
				<h2>Event date: <i className="lime">{event.date_start.toDate().toDateString()} - {event.date_end.toDate().toDateString()}</i></h2>
				<p className="scroll-down">Scroll down to register</p>
							  
				{ (document.registered) && 
			  (<h4>You are registered!</h4>)}

			<div className='partner-bg'> </div>
			<p className="deadline">Registration deadline {''}
			<span style={{color: '#7bdcb5'}}>{event.deadline.toDate().toDateString()} </span>{' '}</p>

				<p className="info" dangerouslySetInnerHTML={{__html: eventInfo}}></p>
							<div className="info-rules">
			  <b className="note">NOTE: &nbsp; &nbsp;</b>
			  This is a free event for students. By registering to the event you agree to our rules:
			  <ol>
				<li>One can not register after the registration deadline. This is set for {event.deadline.toDate().toDateString()}.</li>
				<li>One can not unregister after the un-register deadline. This is set for {event.unregister_deadline.toDate().toDateString()}.</li>
				<li>Participation if free of charge - we are all poor students here. We will, however, enforce a 500 SEK no-show fee for those who sign up, get accepted and do not show up to the event, hindering someone else from joining instead.</li>
			  </ol>
			</div>

			</div>
			<br></br>

      { event.closed && (<p style={{color: "rgb(253, 2, 2)"}}>Registration period is closed!</p>)   }
          

		{( !(event.closed) && !(document.registered) && (!isPressedRegister)  && 
           (<button className="btn-register" style={{margin: "0 0 100px 0", float: "right", padding: "10px 30px"}} onClick={handleRegister}>Register</button>))} 

		{ ( !(document.registered) && isPressedRegister && !cancel) &&
          (<form onSubmit={handleSubmit} className="auth-form" style={{margin: "-20px 0 0 0"}}>
		   <h2>Registration info</h2>
		   <label>
           <span>Field of study:</span>
           <input
           required
           type="text" 
           onChange={(e) => setFieldOfStudy(e.target.value)} 
           value={fieldOfStudy}
           />
		   </label>
		   <label>
           <span>Hobbies and other interests?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setHobbies(e.target.value)} 
           value={hobbies}
           />
		   </label>
		   <label>
           <span>What does innovation mean to you?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setQuestion1(e.target.value)} 
           value={question1}
           />
		   </label>
		   <label>
           <span>What do you expect from Stockholm Innovation Bootcamp?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setQuestion2(e.target.value)} 
           value={question2}
           />
		   </label>


		   <label>
           <span>How did you find about the Stockholm Innovation Bootcamp?</span>
           <input
           required
           type="text" 
           onChange={(e) => setQuestion3(e.target.value)} 
           value={question3}
           />
		   </label>

		   <label>
           <span>Anything else you want to share?</span>
           <textarea
           type="text" 
           onChange={(e) => setQuestion4(e.target.value)} 
           value={question4}
           />
		   </label>


		   <label>
           <span>Can you participate at the event in Stochkolm between 27-29 of January 2023?</span>
		   <input required type="radio" value="yes" onChange={(e) => setParticipate(e.target.value)}  name="participate" /> Yes
		   <br />
           <input required type="radio" value="no" onChange={(e) => setParticipate(e.target.value)} name="participate" /> No
		   </label>

		   <label>
		   <input required type="checkbox" value="yes" onChange={(e) => setParticipate(e.target.value)}  name="participate" /> &nbsp; I have read and agreed to the <Link to="/privacy" style={{margin: "0 30px 0 0", color: "#3bdd92"}} > Privacy Policy </Link>
		   <br />
		   </label>

		   <button className="btn-register" style={{margin: "0 0 10px 0"}}>Register</button>
		   
		   <button className="btn-cancel" style={{margin: "0 0 10px 10px"}} onClick={handleCancel}>Cancel</button>

		   
		   </form>)}

        { (document.registered) && 
          (<p className='congrats-text'>Congrats! you have signed up for this year's bootcamp</p>)} 

          <br></br>

          { (!event.unbookable &&  document.registered && !isPressedUnegister) && 
			(<button className="btn-delete" onClick={handleUnregister} style={{margin: "0 0 30px 0"}}>Unregister</button>)} 

      { isPressedUnegister &&
          (<form onSubmit={handleDelete} className="auth-form">
			<h2>Are you sure you want to unregister?</h2>
			<br/>
      
          <button className="btn">Yes</button>
 
          <button className="btn-cancel" onClick={handleCancel}>Cancel</button>
     
          </form>)}
		</div>
    </div>
    </div>
  )
}
