import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { Link } from 'react-router-dom'

//styles
import './Login.css'

export default function Login() {
const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const {login, isPending, error} = useLogin()

const handleSubmit = (e) => {
e.preventDefault()
login(email, password)

}

return (
<div>
  <div className="desktop">
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>Login</h2>
      <label>
        <span>Email:</span>
        <input
          required 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
        value={email}
        />
      </label>
      <label>
        <span>Password:</span>
        <input
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
        value={password}
        />
      </label>
		{!isPending && <button className="btn" style={{float: "right", padding: " 8px 25px"}}>Login</button>}
      {isPending && <button className="btn" disabled style={{float: "right", padding: " 8px 25px"}}>loading</button>}
      {error && <div className='error'>{error}</div>}
      <label>
        <p className='change'><Link  to="/forgotPassword" style={{ textDecoration: 'none', color:"#efefef" }}>Forgot password?</Link></p>
      </label>
    </form>

  </div>
  <div className="mobile">
    <form onSubmit={handleSubmit} className="auth-form">
      <h2>Login</h2>
      <label>
        <span>Email:</span>
        <input
          required 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
        value={email}
        />
      </label>
      <label>
        <span>Password:</span>
        <input
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
        value={password}
        />
      </label>
      {!isPending && <button className="btn">Login</button>}
      {isPending && <button className="btn" disabled>loading</button>}
      {error && <div className='error'>{error}</div>}
      <label>
        <p className='change'><Link  to="/forgotPassword" style={{ textDecoration: 'none', color:"#efefef" }}>Forgot password?</Link></p>
      </label>
    </form>

  </div>

</div>
)
}
