import { useEffect, useState, useRef } from "react"
import { projectFirestore } from "../firebase/config"

export const useCollection = (collection, _query, _orderBy) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)
  const query = useRef(_query).current
  const orderBy = useRef(_orderBy).current

  useEffect(() => {
    let ref = projectFirestore.collection(collection)
    let results = []

    if (query) {
      const ref1 = ref.where(...query[0])
      const unsubscribe = ref1.onSnapshot(snapshot => {
        
        snapshot.docs.forEach(doc => {
          results.push({...doc.data(), id: doc.id})
        });
        
        // update state
        //setDocuments(results)
        setError(null)
      }, error => {
        console.log(error)
        setError('could not fetch the data')
      })
      ref = ref.where(...query[1])
      //console.log(ref)
    }
    if (orderBy) {
      ref = ref.orderBy(...orderBy)
    }

    const unsubscribe = ref.onSnapshot(snapshot => {
     
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id})
      });
      
      // update state
      setDocuments(results)
      setError(null)
    }, error => {
      console.log(error)
      setError('could not fetch the data')
    })

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [collection, query, orderBy])

  return { documents, error }
}
