import { useState, useEffect } from 'react'
import { useSignup } from '../../hooks/useSignup'
import { useFirestore } from "../../hooks/useFirestore"
import { projectFirestore } from "../../firebase/config"
import PasswordStrengthBar from 'react-password-strength-bar';
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'


// styles
import './Signup.css'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [rePassword, setRePassword] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [fieldOfStudy, setFieldOfStudy] = useState('')
	const [hobbies, setHobbies] = useState('')
	const [question1, setQuestion1] = useState('')
	const [question2, setQuestion2] = useState('')
	const [question3, setQuestion3] = useState('')
	const [question4, setQuestion4] = useState('')
  const [event, setEvent] = useState(null)
  const [erro, setErro] = useState(null)
	const [participate, setParticipate] = useState('')
  const {signup, isPending, error} = useSignup()
  const [passReg, setPassReg] = useState(false)
  const [emailReg, setEmailReg] = useState(false)
  const [passCheckError, setPassCheckError] = useState(false)
  const history = useHistory()
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&+=]).{6,}$/;
  
  useEffect(() => {
            if(!emailRegex.test(email)){
              setEmailReg(true)
            }
            if(emailRegex.test(email)){
              setEmailReg(false)
            }
    
            if(!passRegex.test(password)){
                setPassReg(true)
            }
            if(passRegex.test(password)){
              setPassReg(false)
            }
            if (password === rePassword) {
              setPassCheckError(false);
            } else {
              setPassCheckError(true)
            }
    }, [email, password, rePassword])



    useEffect(() => {
      const ref = projectFirestore.collection('2023/events/events').doc('SIB2023')
  
      const unsubscribe = ref.onSnapshot(snapshot => {
        // need to make sure the doc exists & has data
        if(snapshot.data()) {
          setEvent({...snapshot.data(), id: snapshot.id})
          setErro(null)
        }
        else {
          setErro('No such document exists')
        }
      }, err => {
        console.log(err.message)
        setErro('failed to get document')
      })
  
      // unsubscribe on unmount
      return () => unsubscribe()
  
    }, ['2023/events/events/SIB2023'])

    if (erro) {
      return <div className="error">{error}</div>
    }

    if (!event) {
      return <div className="loading">Loading...</div>
    }

    const eventInfo = String(event.info).replace(/\\n/g, "<br/><br/>")

    const handleCPassword = (e) => {
      setRePassword(e.target.value);
  }
  
  const handleSubmit = (e) => {
    if(!emailRegex.test(email)){
      alert("Please enter a valid e-mail address");  
    }
    
    else{
      if(!passRegex.test(password)){
        alert("Please choose a password according to the criteria");  
      }

      else{
        e.preventDefault()
        signup(email, password, displayName, fieldOfStudy, hobbies, question1, question2, question3, question4, participate )
      }
  
  }
}


  return (
      
      <div>
			<div className="desktop">
			<div>
			<div className="project-summary">
			  <h3>{event.name}</h3>
			<h2>Event date: <i className="lime">{event.date_start.toDate().toDateString()} - {event.date_end.toDate().toDateString()}</i></h2>
			  <p className="scroll-down">Scroll down to register</p>
			<div className='partner-bg'> </div>
			<p className="deadline">Registration deadline {''}
			<span style={{color: '#3bdd92'}}>{event.deadline.toDate().toDateString()} </span>{' '}</p>
			  <p className="info" dangerouslySetInnerHTML={{__html: eventInfo}}></p>
			{/*<div className="info-rules">
			  <b className="note" id="desktopNote">NOTE: &nbsp; &nbsp;</b>
			  This is a free event for students. By registering to the event you agree to our rules:
			  <ol>
				<li>One can not register after the registration deadline. This is set for {event.deadline.toDate().toDateString()}.</li>
				<li>One can not unregister after the un-register deadline. This is set for {event.unregister_deadline.toDate().toDateString()}.</li>
				<li>Participation if free of charge - we are all poor students here. We will, however, enforce a 500 SEK no-show fee for those who sign up, get accepted and do not show up to the event, hindering someone else from joining instead.</li>
			  </ol>
  </div> */}

			</div>

			<br></br>

			{ event.closed && (<p style={{color: "rgb(253, 2, 2)", margin: "0 0 20px 0"}}>Registration period is closed!</p>)   }
          

		{   !(event.closed) && 
          (<form onSubmit={handleSubmit} className="auth-form">
       <h2>Sign up & Register</h2>
      <label>
        <span>Email:</span>
        <input
          required 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
        />
      </label>
      { (email && emailReg) && <div className='error'>{"Please provide a valid Email address."}</div>}
      <label >
        <span>Password:</span>
        <input
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password}
        />
      </label>
      {(password && !passReg) && (<PasswordStrengthBar minLength={6} password={password} />)}
      { (password && passReg) && <div className='error'>{"Password must be at least one uppercase, one lowercase, one special character and one number and 6 characters long."}</div>}
      <label >
        <span>Confirm Password:</span>
        <input
          required
          type="password" 
          onChange={handleCPassword} 
          value={rePassword}
        />
      </label>
      <label>
        {  (passCheckError && ( password && rePassword) ) && <div className='error'>{"Passwords do not match."}</div>}
      </label>
      <label>
        <span>Full Name:</span>
        <input
          required
          type="text" 
          onChange={(e) => setDisplayName(e.target.value)} 
          value={displayName}
        />
      </label>
		   <label>
			 <span>Field of study:</span>
           <input
			 required
           type="text" 
           onChange={(e) => setFieldOfStudy(e.target.value)} 
           value={fieldOfStudy}
           />
		   </label>
		   <label>
           <span>Hobbies and other interests?</span>
           <textarea
           required
			 type="text"
           onChange={(e) => setHobbies(e.target.value)} 
           value={hobbies}
           />
		   </label>
		   <label>
           <span>What does innovation mean to you?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setQuestion1(e.target.value)} 
           value={question1}
           />
		   </label>
		   <label>
           <span>What do you expect from Stockholm Innovation Bootcamp?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setQuestion2(e.target.value)} 
           value={question2}
           />
		   </label>


		   <label>
           <span>How did you find about the Stockholm Innovation Bootcamp?</span>
           <input
           required
           type="text" 
           onChange={(e) => setQuestion3(e.target.value)} 
           value={question3}
           />
		   </label>

		   <label>
           <span>Anything else you want to share?</span>
           <input
           required
           type="text" 
           onChange={(e) => setQuestion4(e.target.value)} 
           value={question4}
           />
		   </label>


		   <label>
           <span>Can you participate at the event in Stockholm between 27-29 of January 2023?</span>
		   <input required className="radio-buttons-participate" type="radio" value="yes" onChange={(e) => setParticipate(e.target.value)}  name="participate" /> Yes
		   <br />
           <input required className="radio-buttons-participate" type="radio" value="no" onChange={(e) => setParticipate(e.target.value)} name="participate" /> No
		   </label>

		   <label>
		   <input required className="radio-buttons-participate" type="checkbox" value="yes" onChange={(e) => setParticipate(e.target.value)}  name="participate" /> &nbsp; I have read and agreed to the <Link to="/privacy" style={{ color: "#3bdd92"}} > Privacy Policy </Link>and <Link to="#desktopNote" style={{color: "#3bdd92"}} >Terms</Link>
		   <br />
		   </label>

		    {/*<button className="btn-register" style={{margin: "0 0 10px 0"}}>Register</button>*/}
        {!isPending && <button className="btn" disabled={passReg || emailReg}>Register</button>}
      {isPending && <button className="btn" disabled>loading</button>}
      {error && <div className='error'>{error}</div>}
		   
		   
		   </form>)}

          <br></br>

    </div>
    </div>
			<div className="mobile">
			<div>
			  <div className="project-summary">

				<h3>{event.name}</h3>
				<h2>Event date: <i className="lime">{event.date_start.toDate().toDateString()} - {event.date_end.toDate().toDateString()}</i></h2>
				<p className="scroll-down">Scroll down to register</p>
							  
				{ (document.registered) && 
			  (<h4>You are registered!</h4>)}

			<div className='partner-bg'> </div>
			<p className="deadline">Registration deadline {''}
			<span style={{color: '#7bdcb5'}}>{event.deadline.toDate().toDateString()} </span>{' '}</p>

				<p className="info" dangerouslySetInnerHTML={{__html: eventInfo}}></p>
							{/*<div className="info-rules">
			  <b className="note">NOTE: &nbsp; &nbsp;</b>
			  This is a free event for students. By registering to the event you agree to our rules:
			  <ol>
				<li>One can not register after the registration deadline. This is set for {event.deadline.toDate().toDateString()}.</li>
				<li>One can not unregister after the un-register deadline. This is set for {event.unregister_deadline.toDate().toDateString()}.</li>
				<li>Participation if free of charge - we are all poor students here. We will, however, enforce a 500 SEK no-show fee for those who sign up, get accepted and do not show up to the event, hindering someone else from joining instead.</li>
			  </ol>
			</div> */}
        </div> 
			<br></br>
      <br/>
      { event.closed && (<p style={{color: "rgb(253, 2, 2)"}}>Registration period is closed!</p>)   }
          

		

		{ !(event.closed) && 
          (<form onSubmit={handleSubmit} className="auth-form" style={{margin: "-20px 0 0 0"}}>
		   <h2>Sign up & Register</h2>
      <label>
        <span>Email:</span>
        <input
          required 
          type="email" 
          onChange={(e) => setEmail(e.target.value)} 
          value={email}
        />
      </label>
      { (email && emailReg) && <div className='error'>{"Please provide a valid Email address."}</div>}
      <label >
        <span>Password:</span>
        <input
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password}
        />
      </label>
      {(password && !passReg) && (<PasswordStrengthBar minLength={6} password={password} />)}
      { (password && passReg) && <div className='error'>{"Password must be at least one uppercase, one lowercase, one special character and one number and 6 characters long."}</div>}
      <label >
        <span>Confirm Password:</span>
        <input
          required
          type="password" 
          onChange={handleCPassword} 
          value={rePassword}
        />
      </label>
      <label>
        {  (passCheckError && ( password && rePassword) ) && <div className='error'>{"Passwords do not match."}</div>}
      </label>
      <label>
        <span>Full Name:</span>
        <input
          required
          type="text" 
          onChange={(e) => setDisplayName(e.target.value)} 
          value={displayName}
        />
      </label>
		   <label>
           <span>Field of study:</span>
           <input
           required
           type="text" 
           onChange={(e) => setFieldOfStudy(e.target.value)} 
           value={fieldOfStudy}
           />
		   </label>
		   <label>
           <span>Hobbies and other interests?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setHobbies(e.target.value)} 
           value={hobbies}
           />
		   </label>
		   <label>
           <span>What does innovation mean to you?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setQuestion1(e.target.value)} 
           value={question1}
           />
		   </label>
		   <label>
           <span>What do you expect from Stockholm Innovation Bootcamp?</span>
           <textarea
           required
           type="text" 
           onChange={(e) => setQuestion2(e.target.value)} 
           value={question2}
           />
		   </label>


		   <label>
           <span>How did you find about the Stockholm Innovation Bootcamp?</span>
           <input
           required
           type="text" 
           onChange={(e) => setQuestion3(e.target.value)} 
           value={question3}
           />
		   </label>

		   <label>
           <span>Anything else you want to share?</span>
           <textarea
           type="text" 
           onChange={(e) => setQuestion4(e.target.value)} 
           value={question4}
           />
		   </label>


		   <label>
           <span>Can you participate at the event in Stochkolm between 27-29 of January 2023?</span>
		   <input required type="radio" value="yes" onChange={(e) => setParticipate(e.target.value)}  name="participate" /> Yes
		   <br />
           <input required type="radio" value="no" onChange={(e) => setParticipate(e.target.value)} name="participate" /> No
		   </label>

		   <label>
		   <input required type="checkbox" value="yes" onChange={(e) => setParticipate(e.target.value)}  name="participate" /> &nbsp; I have read and agreed to the <Link to="/privacy" style={{margin: "0 30px 0 0", color: "#3bdd92"}} > Privacy Policy </Link>
		   <br />
		   </label>

		   {/*<button className="btn-register" style={{margin: "0 0 10px 0"}}>Register</button>*/}
       {!isPending && <button className="btn" disabled={passReg || emailReg}>Register</button>}
      {isPending && <button className="btn" disabled>loading</button>}
      {error && <div className='error'>{error}</div>}
		   
		   </form>)}


          <br></br>
		</div>
    </div>
    </div>
  )
}
