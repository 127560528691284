import { NavLink } from "react-router-dom"

// styles & images
import "./Sidebar.css"
import DashboardIcon from '../assets/calendar.png'
import ProfileIcon from '../assets/account.png'
import LockIcon from '../assets/lock.png'
import {useAuthContext} from '../hooks/useAuthContext'

export default function Sidebar() {
const {user} = useAuthContext()

return (
<div>
  <div className="desktop">
	<div className="sidebar">
      <div className="sidebar-content">
		<div className="user">
          <p>Hej, <br/> {user.displayName}</p>  
		</div>  
		<nav className="links">
          <ul>
			<li>
              <NavLink exact to="/">
				<img src={DashboardIcon} alt="dashboard icon" />
				<span>Events</span>
              </NavLink>
			</li>
			<li>
              <NavLink to="/profile">
				<img src={ProfileIcon} alt="profile icon" />
				<span>My profile</span>
              </NavLink>
			</li>
			<li>
              <NavLink to="/privacy">
				<img src={LockIcon} alt="lock icon" />
				<span>Privacy Policy</span>
              </NavLink>
			</li>
          </ul>
		</nav>
      </div>
	</div>
  </div>
  <div className="mobile">
	<div className="sidebar-mobile">
      <div className="sidebar-content-mobile">
		<div className="user">
          <p>Hej, <br/> {user.displayName}</p>  
		</div>  
		<nav className="links">
          <ul>
			<li>
              <NavLink exact to="/">
				<img src={DashboardIcon} alt="dashboard icon" />
				<span>Events</span>
              </NavLink>
			</li>
			<li>
              <NavLink to="/profile">
				<img src={ProfileIcon} alt="profile icon" />
				<span>My profile</span>
              </NavLink>
			</li>
			<li>
              <NavLink to="/privacy">
				<img src={LockIcon} alt="lock icon" />
				<span>Privacy Policy</span>
              </NavLink>
			</li>
          </ul>
		</nav>
      </div>
	</div>
  </div>

</div>
)
}
