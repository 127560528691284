import { useState, useEffect } from 'react'
import { projectAuth, projectFirestore } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const { dispatch } = useAuthContext()

  const signup = async (email, password, displayName, fieldOfStudy, hobbies, question1, question2, question3, question4, participate ) => {
    setError(null)
    setIsPending(true)
  
    try {

      // signup
      const res =  await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }
      

      // add display name to user
       await res.user.updateProfile({ displayName})

      //create a user document
       await projectFirestore.collection('2023').doc('users').collection('users').doc(res.user.uid).set({
        online: true,
        displayName, 
        email,
        fieldOfStudy, 
        hobbies, 
        answers: [question1, question2, question3, question4],
        participate,
        registered: true,
        verified: false
      })

      // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })
      
      //await projectAuth.currentUser.sendEmailVerification()
      
      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }
    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
      }
    }
  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending }
}
