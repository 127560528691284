import ProjectList from '../../components/ProjectList'
import {useCollection} from '../../hooks/useCollection'

//styles
import './Dashboard.css'

export default function Dashboard() {

const {documents, error} =  useCollection('2023/events/events', )

  return (
    <div>
        <h2 className="page-title">Event</h2>
        {error && <p className="error">{error}</p>}
        {documents && <ProjectList events={documents} />} 
      </div>
  )

}
