import { Link } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import { useDocument } from '../hooks/useDocument'




//styles
import './ProjectList.css'

export default function ProjectList({ events }) {

	const {user} = useAuthContext()
	const { document, errors } = useDocument('2023/users/users', user.uid)

	if (!document) {
		return <div className="loading">Loading...</div>
	} else


		return (
				<div>
				<div className="desktop">
				<div className='project-list'>
				{events.length === 0 && <p>No Events yet, Stay tuned!!</p>}
			{events.map(event => (
					<Link key={event.id} className="card" to={`event/${event.title}`} >

				{ (document.registered) && 
				  (<h4>You are registered!</h4>)}
				{ (!document.registered) && 
				  (<h4 style={{color: "rgb(253, 2, 2)"}}>Not registered! <br/> Click here to register</h4>)}


					<div>
					<div className='card-bc'> </div>
					<h3>{event.title}</h3>
					<p>Registration deadline {''}
					<span style={{color: '#7bdcb5'}}>{event.deadline.toDate().toDateString()} </span>{' '}</p>
					</div>
			        </Link>
					

			))}
			</div>
				</div>
				<div className="mobile">
				<div className='project-list-mobile'>
				{events.length === 0 && <p>No Events yet, Stay tuned!!</p>}
			{events.map(event => (
					<Link key={event.id} to={`event/${event.title}`} style={{ textDecoration: 'none' }} >


					<div className="card">
					{ (document.registered) && 
					  (<h4>You are registered!</h4>)}
				{ (!document.registered) && 
				  (<h4 style={{color: "rgb(253, 2, 2)"}}>Not registered! <br/> Click here to register</h4>)}


					<div className='card-bc'> </div>
					<h3>{event.title}</h3>
					<p>Registration deadline {''}
					<span style={{color: '#7bdcb5'}}>{event.deadline.toDate().toDateString()} </span>{' '}</p>
					</div>
			        </Link>


			))}
			</div>
				</div>
				</div>
		)
}
