import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'

// styles
import './App.css'


// pages & components
import Dashboard from './pages/dashboard/Dashboard'
import Login from './pages/login/Login'
import ForgotPassword from './pages/forgotPassword/ForgotPassword'
import Signup from './pages/signup/Signup'
import NotFound from './pages/404/NotFound'
import Project from './pages/project/Project'
import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Profile from './pages/profile/Profile'
import Privacy from './pages/privacy/Privacy'
//import Verify from './pages/verify/Verify'
import Handler from './pages/handler/Handler'

function App() {
const {user, authIsReady}  = useAuthContext()

return (

/*<div>
  <div className="desktop">
	<div className="App">
	  {authIsReady && (
	  <BrowserRouter>
		{(user && user.emailVerified) && <Sidebar />}
		<div className="container">
		  {(!user || user.emailVerified ) &&<Navbar />}
		  <Switch>
			<Route exact path="/">
			  {!user && <Redirect to="/signup" />}
			  {(user && user.emailVerified) && <Dashboard />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			</Route>
			<Route path="/event/:id">
			  {!user && <Redirect to="/login" />}
			  {(user && user.emailVerified) && <Project />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			</Route>
			<Route path="/profile">
			  {!user && <Redirect to="/login" />}
			  {(user && user.emailVerified)  && <Profile />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
		  </Route>
			  <Route path="/privacy">
			  {<Privacy />}
		  </Route>
			<Route path="/login">
			  {(user && user.emailVerified) && <Redirect to= "/" />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			  {!user && <Login />}
			</Route>
			<Route path="/forgotPassword">
			  {(user && user.emailVerified) && <Redirect to= "/" />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			  {!user && <ForgotPassword />}
			</Route>
			<Route path="/signup">
			  {(user && user.emailVerified)  && <Redirect to= "/" />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			  {!user && <Signup />}
			</Route>
			<Route path="/verify">
			  {(user && user.emailVerified) && <Redirect to= "/" />}
			  {!user && <Redirect to="/login" />}
			  {(user && !user.emailVerified ) && <Verify />}
			</Route>
			<Route path="/handler/*">
			  { (!user) && <Handler /> }
			  { (user && user.emailVerified) && <Redirect to= "/" />}
			  { (user && !user.emailVerified ) && <Handler /> }
			</Route>
			<Route path="*">
			  {(user && user.emailVerified) && <NotFound />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			  {!user && <NotFound />}
			</Route>
		  </Switch>
		</div>
	  </BrowserRouter>
	  )}
	</div>
  </div>
  <div className="mobile">
	<div className="App">
	  {authIsReady && (
	  <BrowserRouter>
		<div className="container">
		  {(!user || user.emailVerified ) &&<Navbar />}
		  {(user && user.emailVerified) && <Sidebar />}
		  <Switch>
			<Route exact path="/">
			  {!user && <Redirect to="/signup" />}
			  {(user && user.emailVerified) && <Dashboard />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			</Route>
			<Route path="/event/:id">
			  {!user && <Redirect to="/login" />}
			  {(user && user.emailVerified) && <Project />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			</Route>
			<Route path="/profile">
			  {!user && <Redirect to="/login" />}
			  {(user && user.emailVerified)  && <Profile />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			</Route>
			<Route path="/login">
			  {(user && user.emailVerified) && <Redirect to= "/" />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			  {!user && <Login />}
			</Route>
			<Route path="/forgotPassword">
			  {(user && user.emailVerified) && <Redirect to= "/" />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			  {!user && <ForgotPassword />}
			</Route>
			<Route path="/signup">
			  {(user && user.emailVerified)  && <Redirect to= "/" />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			  {!user && <Signup />}
		  </Route>
			  <Route path="/privacy">
			  {<Privacy />}
		  </Route>

			<Route path="/verify">
			  {(user && user.emailVerified) && <Redirect to= "/" />}
			  {!user && <Redirect to="/login" />}
			  {(user && !user.emailVerified ) && <Verify />}
			</Route>
			<Route path="/handler/*">
			  {(user && user.emailVerified) && <Redirect to= "/" />}
			  {!user && <Handler />}
			  {(user && !user.emailVerified ) && <Handler />}
			</Route>
			<Route path="*">
			  {(user && user.emailVerified) && <NotFound />}
			  {(user && !user.emailVerified) && <Redirect to= "/verify" />}
			  {!user && <NotFound />}
			</Route>
		  </Switch>
		</div>
	  </BrowserRouter>
	  )}
	</div>
  </div>
</div> */

<div>
  <div className="desktop">
	<div className="App">
	  {authIsReady && (
	  <BrowserRouter>
		{(user) && <Sidebar />}
		<div className="container">
		  {<Navbar />}
		  <Switch>
			<Route exact path="/">
			  {!user && <Redirect to="/signup" />}
			  {(user ) && <Dashboard />}

			</Route>
			<Route path="/event/:id">
			  {!user && <Redirect to="/login" />}
			  {(user) && <Project />}

			</Route>
			<Route path="/profile">
			  {!user && <Redirect to="/login" />}
			  {(user)  && <Profile />}

		  </Route>
			  <Route path="/privacy">
			  {<Privacy />}
		  </Route>
			<Route path="/login">
			  {(user) && <Redirect to= "/" />}

			  {!user && <Login />}
			</Route>
			<Route path="/forgotPassword">
			  {(user) && <Redirect to= "/" />}

			  {!user && <ForgotPassword />}
			</Route>
			<Route path="/signup">
			  {(user)  && <Redirect to= "/" />}

			  {!user && <Signup />}
			</Route>
			<Route path="/handler/*">
			  { (!user) && <Handler /> }
			  { (user) && <Redirect to= "/" />}
			</Route>
			<Route path="*">
			  {(user) && <NotFound />}
			  {!user && <NotFound />}
			</Route>
		  </Switch>
		</div>
	  </BrowserRouter>
	  )}
	</div>
  </div>
  <div className="mobile">
	<div className="App">
	  {authIsReady && (
	  <BrowserRouter>
		<div className="container">
		  {<Navbar />}
		  {(user) && <Sidebar />}
		  <Switch>
			<Route exact path="/">
			  {!user && <Redirect to="/signup" />}
			  {(user) && <Dashboard />}

			</Route>
			<Route path="/event/:id">
			  {!user && <Redirect to="/login" />}
			  {(user) && <Project />}

			</Route>
			<Route path="/profile">
			  {!user && <Redirect to="/login" />}
			  {(user)  && <Profile />}

			</Route>
			<Route path="/login">
			  {(user) && <Redirect to= "/" />}

			  {!user && <Login />}
			</Route>
			<Route path="/forgotPassword">
			  {(user) && <Redirect to= "/" />}
			  {!user && <ForgotPassword />}
			</Route>
			<Route path="/signup">
			  {(user)  && <Redirect to= "/" />}
			  {!user && <Signup />}
		  </Route>
			  <Route path="/privacy">
			  {<Privacy />}
		  </Route>

			<Route path="/verify">
			  {(user) && <Redirect to= "/" />}
			  {!user && <Redirect to="/login" />}

			</Route>
			<Route path="/handler/*">
			  {(user) && <Redirect to= "/" />}
			  {!user && <Handler />}
			</Route>
			<Route path="*">
			  {(user) && <NotFound />}

			  {!user && <NotFound />}
			</Route>
		  </Switch>
		</div>
	  </BrowserRouter>
	  )}
	</div>
  </div>
</div>
);
}

export default App
