import './404.css'

export default function NotFound() {

  return (
    <div>
      <h style={{ textDecoration: 'none', color:"#7bdcb5", alignSelf : 'center' }}>404 ERROR</h>
      <p style={{ textDecoration: 'none', color:"#7bdcb5", alignSelf : 'center' }}> Oops! page not found </p>
  </div> 

  )
}
