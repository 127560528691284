import './Privacy.css'


export default function Privacy() {

return (
<div>
  <div class="desktop">
	<div className="privacy-container">
	  <div className="container-fluid">
		<h1>Our Privacy Policy</h1>
		<br/> <br/>
		<p>
		  Stockholm Innovation Bootcamp is an initiative organized by and through the non-profit organization Hailey 802526-7074 (“Hailey”). Haileys aim is to increase multidisciplinary teamwork and inspire innovation through the Bootcamp.
		  <br/> <br/>
		  At Hailey one of our main priorities is the privacy of our website´s visitors and the applicants of Stockholm Innovation Bootcamp. Through this Privacy Policy, we would like to inform you how Hailey collects, records and processes your personal and sensible data, pursuant to the Regulation (UE) 2016/679 of April 27, 2016.
		  <br/> <br/>
		  Our Privacy Policy applies to our online activities and to visitors on our website as well as attendees for Stockholm Innovation Bootcamp, with regards to the information that they share with Hailey through the sign up sheets on the website or otherwise. This Policy is not applicable to any information collected offline or via channels other than this website.


		</p>
	  </div>	

	  <div className="container">
		<h2>1. Definitions</h2>
		1.1 Applicants: Applicants: include both the users visiting the website for gathering information or for contacting Hailey and the people that through the website will apply to attend the Stockholm Innovation Bootcamp events (either as a student or as a mentor).
		<br/> <br/>
		1.2 Data Controller: is the legal person which determines the purposes and means of the Processing of personal and sensible data. It is Hailey, a nonprofit organization that organizes and runs the Stockholm Innovation Bootcamp events.
		<br/> <br/>
		1.3 Personal data: any information relating to an identified or identifiable natural person, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
		<br/> <br/>
		1.4 Processing: any operation or set of operations which is performed on personal and sensible data or on sets of personal and sensible data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
		<br/> <br/>
		1.5 Sensible Data: personal and sensible data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation. Hailey doesn't ask for sensible data. Nevertheless the sensible data that might be volunteered by the applicants will be treated with the highest standards, as required by the applicable legislation.
		<br/> <br/>
		1.6 Regulation: Regulation (UE) 2016/679 of April 27, 2016.
		<br/> <br/>
		1.7 Services: are offered by Hailey through the website. To gather information about the Stockholm Innovation Bootcamp, to contact Hailey for questions or queries, to apply to the events organized by Hailey (both in-person or online), to become a mentor in the latter and to become a partner of Hailey.
		<br/> <br/>
		1.8 Website: the website <a href="www.stockholminnovationbootcamp.com">www.stockholminnovationbootcamp.com</a>.
		<br/> <br/>
		1.9 Signup System: the signup system <a href="signup.stockholminnovationbootcamp.com">signup.stockholminnovationbootcamp.com</a>.
		
		<h2>2. Data Controller</h2>
		The Data Controller of the Processing of Personal and Sensible Data is Hailey r.f, with legal seat in Professorsslingan 45 (Sweden). Any request related to the Processing carried out by Hailey concerning the personal and sensible data, also in relation to the exercise of the Rights according to the following article 7, shall be addressed to Alexander Suliman via e-mail to the following address: <a href="mailto:alex@stockholminnovationbootcamp.com">alex@stockholminnovationbootcamp.com</a>.
		
		<h2>3. Processing purposes and legal basis for processing of personal and sensible data</h2>
		The Website collects and processes personal and sensible data for the following purposes:
		<br/> <br/>
		<ol>
		  <li>Allowing a correct technical use of the Website, essentially through the use of Cookies (see <a href="https://stockholminnovationbootcamp.com/cookie.html">Cookie Policy</a>).
		  </li>
		  <br/>
		  <li>Offering the requested Services to the Applicants. <br/>Processing of personal and sensible data for the purpose indicated is necessary for the fulfilment of the requested Services and it is based on the free, specific, informed and unequivocal consent of the Applicants. The potential refusal of the Applicants to provide the consent for the processing will impede the fulfilment of the requested Services.

		  </li>
		</ol>
		<h2>4. Means of collection</h2>
		Hailey collects the personal and sensible data through the following means of collections:
		<ol>
		  <li>personal and sensible data automatically collected by the Website through the Cookies (see  <a href="https://stockholminnovationbootcamp.com/cookie.html">Cookie Policy</a>)</li>
		  <li>personal and sensible data provided freely by the Applicants, by filling in the application forms on the Signup System</li>
		  <li>personal and sensible data expressly provided by the Applicants to Hailey through offline means, such as via e-mail, mail or phone calls</li>
		</ol>

		<h2>5. Categories of recipients of personal and sensible data</h2>
		Personal and sensible data may be disclosed and communicated for the purposes indicated in above to Members of the Board of Hailey r.f., volunteers at Stockholm Innovation Bootcamp or other events, internal Collaborators, IT services providers.

		<h2>6. Personal and sensible data retention period</h2>

		Personal and sensible data is stored by the Data Controller for the time strictly necessary to achieve the purposes for which the Personal and Sensible Data are collected, as indicated in the article 3. In particular, the Data Controller stores the Personal and Sensible Data for the entire time necessary to fulfill the activities connected with the requested Services for a maximum period of 24 months since the collection of consent or, if subsequent, the date of the last interaction between the Applicants and Hailey.
		<br/> <br/>
		Applicants always reserve the right to withdraw the consent as indicated in the following article 7 lit. a. ,
		<br/> <br/>
		In any case, the Data Controller is authorized to retain all or part of the Personal and Sensible Data in relation to the aforementioned Processing, for the maximum period of 10 years since the last activity performed in favor of the Applicants to fulfill the Services, only to the extent required to fulfill any legal or fiscal obligations and for the possible assessment, exercise and defense of its rights before judicial authorities.
		<br/> <br/>
		Once these retention periods have expired, the Data Controller will carry out the automatic erasure of all Personal and Sensible Data collected; otherwise it will irreversibly transform them into anonymous form.

		<h2>7. Applicants’ rights</h2>

		Pursuant to article 13 of the Regulation, during the retention period indicated in the previous article 6 of this Privacy Policy, the Applicants has the right to:
		<br/> <br/>
		<ol>
		  <li>Withdraw of consent - with reference to the Processing, withdraw the consent at any time, and the withdrawal of consent shall not affect the lawfulness of the previous Processing, by sending an e-mail to the controller (article 7 Regulation);</li><br/>

		  <li>Access to personal and sensible data - obtain access to the personal and sensible data and to the information on the Processing and any possible copy in electronic form (article 15 Regulation);</li><br/>

		  <li>Rectification or integration of personal and sensible data - ask for rectification and/or integration of personal and sensible data, without undue delay (article 16 Regulation);</li><br/>

		  <li>Erasure of personal and sensible data - where specific grounds apply (for example unlawful data process, withdrawal of consent, absence of purposes for Processing) ask the erasure of personal and sensible data, without undue delay (article 17 Regulation);</li><br/>

		  <li>Restriction of Processing - where specific grounds apply (for example inaccuracy of personal and sensible data, Processing is unlawful, exercise of legal claims) ask the restriction of personal and sensible data, without undue delay (article 18 Regulation);</li><br/>

		  <li>Personal and sensible data portability - in case of Processing through automated means, receive its personal and sensible data in a readable common format and, consequently, exercise its so called right to personal and sensible data Portability and transmit those data to a third party (article 20 Regulation);</li><br/>

		  <li>Oppose the Processing - in any case, oppose the Processing of personal and sensible data, by sending an e-mail to the Data Controller ( article 21 Regulation);</li><br/>

		  <li>Data breach - be informed by the controller without undue delay of possible personal and sensible data breach or unauthorized access by third parties to the controller’s systems where personal and sensible data where stored (c.d. data breach - article 34 Regulation);</li><br/>

		  <li>Filing a complaint to the supervisory authority - Filing a complaint to the supervisory authority in the EU Member State of his habitual residence, place of work or place of the alleged infringement of its rights (article 77 Regulation).</li><br/>
		</ol>


		
	  </div>
	</div>
  </div>
  <div class="mobile">
	<div className="privacy-container">
	  <div className="container-fluid">
		<h1>Our Privacy Policy</h1>
		<br/> <br/>
		<p>
		  Stockholm Innovation Bootcamp is an initiative organized by and through the non-profit organization Hailey 802526-7074 (“Hailey”). Haileys aim is to increase multidisciplinary teamwork and inspire innovation through the Bootcamp.
		  <br/> <br/>
		  At Hailey one of our main priorities is the privacy of our website´s visitors and the applicants of Stockholm Innovation Bootcamp. Through this Privacy Policy, we would like to inform you how Hailey collects, records and processes your personal and sensible data, pursuant to the Regulation (UE) 2016/679 of April 27, 2016.
		  <br/> <br/>
		  Our Privacy Policy applies to our online activities and to visitors on our website as well as attendees for Stockholm Innovation Bootcamp, with regards to the information that they share with Hailey through the sign up sheets on the website or otherwise. This Policy is not applicable to any information collected offline or via channels other than this website.


		</p>
	  </div>	

	  <div className="container">
		<h2>1. Definitions</h2>
		1.1 Applicants: Applicants: include both the users visiting the website for gathering information or for contacting Hailey and the people that through the website will apply to attend the Stockholm Innovation Bootcamp events (either as a student or as a mentor).
		<br/> <br/>
		1.2 Data Controller: is the legal person which determines the purposes and means of the Processing of personal and sensible data. It is Hailey, a nonprofit organization that organizes and runs the Stockholm Innovation Bootcamp events.
		<br/> <br/>
		1.3 Personal data: any information relating to an identified or identifiable natural person, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
		<br/> <br/>
		1.4 Processing: any operation or set of operations which is performed on personal and sensible data or on sets of personal and sensible data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
		<br/> <br/>
		1.5 Sensible Data: personal and sensible data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person's sex life or sexual orientation. Hailey doesn't ask for sensible data. Nevertheless the sensible data that might be volunteered by the applicants will be treated with the highest standards, as required by the applicable legislation.
		<br/> <br/>
		1.6 Regulation: Regulation (UE) 2016/679 of April 27, 2016.
		<br/> <br/>
		1.7 Services: are offered by Hailey through the website. To gather information about the Stockholm Innovation Bootcamp, to contact Hailey for questions or queries, to apply to the events organized by Hailey (both in-person or online), to become a mentor in the latter and to become a partner of Hailey.
		<br/> <br/>
		1.8 Website: the website <a href="www.stockholminnovationbootcamp.com">www.stockholminnovationbootcamp.com</a>.
		<br/> <br/>
		1.9 Signup System: the signup system <a href="signup.stockholminnovationbootcamp.com">signup.stockholminnovationbootcamp.com</a>.
		
		<h2>2. Data Controller</h2>
		The Data Controller of the Processing of Personal and Sensible Data is Hailey r.f, with legal seat in Professorsslingan 45 (Sweden). Any request related to the Processing carried out by Hailey concerning the personal and sensible data, also in relation to the exercise of the Rights according to the following article 7, shall be addressed to Alexander Suliman via e-mail to the following address: <a href="mailto:alex@stockholminnovationbootcamp.com">alex@stockholminnovationbootcamp.com</a>.
		
		<h2>3. Processing purposes and legal basis for processing of personal and sensible data</h2>
		The Website collects and processes personal and sensible data for the following purposes:
		<br/> <br/>
		<ol>
		  <li>Allowing a correct technical use of the Website, essentially through the use of Cookies (see <a href="https://stockholminnovationbootcamp.com/cookie.html">Cookie Policy</a>).
		  </li>
		  <br/>
		  <li>Offering the requested Services to the Applicants. <br/>Processing of personal and sensible data for the purpose indicated is necessary for the fulfilment of the requested Services and it is based on the free, specific, informed and unequivocal consent of the Applicants. The potential refusal of the Applicants to provide the consent for the processing will impede the fulfilment of the requested Services.

		  </li>
		</ol>
		<h2>4. Means of collection</h2>
		Hailey collects the personal and sensible data through the following means of collections:
		<ol>
		  <li>personal and sensible data automatically collected by the Website through the Cookies (see  <a href="https://stockholminnovationbootcamp.com/cookie.html">Cookie Policy</a>)</li>
		  <li>personal and sensible data provided freely by the Applicants, by filling in the application forms on the Signup System</li>
		  <li>personal and sensible data expressly provided by the Applicants to Hailey through offline means, such as via e-mail, mail or phone calls</li>
		</ol>

		<h2>5. Categories of recipients of personal and sensible data</h2>
		Personal and sensible data may be disclosed and communicated for the purposes indicated in above to Members of the Board of Hailey r.f., volunteers at Stockholm Innovation Bootcamp or other events, internal Collaborators, IT services providers.

		<h2>6. Personal and sensible data retention period</h2>

		Personal and sensible data is stored by the Data Controller for the time strictly necessary to achieve the purposes for which the Personal and Sensible Data are collected, as indicated in the article 3. In particular, the Data Controller stores the Personal and Sensible Data for the entire time necessary to fulfill the activities connected with the requested Services for a maximum period of 24 months since the collection of consent or, if subsequent, the date of the last interaction between the Applicants and Hailey.
		<br/> <br/>
		Applicants always reserve the right to withdraw the consent as indicated in the following article 7 lit. a. ,
		<br/> <br/>
		In any case, the Data Controller is authorized to retain all or part of the Personal and Sensible Data in relation to the aforementioned Processing, for the maximum period of 10 years since the last activity performed in favor of the Applicants to fulfill the Services, only to the extent required to fulfill any legal or fiscal obligations and for the possible assessment, exercise and defense of its rights before judicial authorities.
		<br/> <br/>
		Once these retention periods have expired, the Data Controller will carry out the automatic erasure of all Personal and Sensible Data collected; otherwise it will irreversibly transform them into anonymous form.

		<h2>7. Applicants’ rights</h2>

		Pursuant to article 13 of the Regulation, during the retention period indicated in the previous article 6 of this Privacy Policy, the Applicants has the right to:
		<br/> <br/>
		<ol>
		  <li>Withdraw of consent - with reference to the Processing, withdraw the consent at any time, and the withdrawal of consent shall not affect the lawfulness of the previous Processing, by sending an e-mail to the controller (article 7 Regulation);</li><br/>

		  <li>Access to personal and sensible data - obtain access to the personal and sensible data and to the information on the Processing and any possible copy in electronic form (article 15 Regulation);</li><br/>

		  <li>Rectification or integration of personal and sensible data - ask for rectification and/or integration of personal and sensible data, without undue delay (article 16 Regulation);</li><br/>

		  <li>Erasure of personal and sensible data - where specific grounds apply (for example unlawful data process, withdrawal of consent, absence of purposes for Processing) ask the erasure of personal and sensible data, without undue delay (article 17 Regulation);</li><br/>

		  <li>Restriction of Processing - where specific grounds apply (for example inaccuracy of personal and sensible data, Processing is unlawful, exercise of legal claims) ask the restriction of personal and sensible data, without undue delay (article 18 Regulation);</li><br/>

		  <li>Personal and sensible data portability - in case of Processing through automated means, receive its personal and sensible data in a readable common format and, consequently, exercise its so called right to personal and sensible data Portability and transmit those data to a third party (article 20 Regulation);</li><br/>

		  <li>Oppose the Processing - in any case, oppose the Processing of personal and sensible data, by sending an e-mail to the Data Controller ( article 21 Regulation);</li><br/>

		  <li>Data breach - be informed by the controller without undue delay of possible personal and sensible data breach or unauthorized access by third parties to the controller’s systems where personal and sensible data where stored (c.d. data breach - article 34 Regulation);</li><br/>

		  <li>Filing a complaint to the supervisory authority - Filing a complaint to the supervisory authority in the EU Member State of his habitual residence, place of work or place of the alleged infringement of its rights (article 77 Regulation).</li><br/>
		</ol>


		
	  </div>
	</div>
  </div>
</div>
)
}
