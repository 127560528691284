import { projectAuth, projectFirestore } from '../../firebase/config'
import { useState, useEffect } from 'react';
import { verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import PasswordStrengthBar from 'react-password-strength-bar';
import { useHistory } from 'react-router-dom'
import { useFirestore } from "../../hooks/useFirestore"
import firebase from 'firebase/app'

const queryString = require('query-string');

export default function Handler(){

	const [password, setPassword] = useState('')
	const [rePassword, setRePassword] = useState('')
	const [passReg, setPassReg] = useState(false)
	const [passCheckError, setPassCheckError] = useState(false)
	const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&+=]).{6,}$/;
	const {updateDocument} = useFirestore('2023/users/users')
	const [isVerify, setIsVerify] = useState(false)
	const [isReset, setIsReset] = useState(false)
	const [error, setError] = useState('')
	const User = firebase.auth().currentUser;


	const history = useHistory()

	const handleCPassword = (e) => {
		setRePassword(e.target.value);
	}

	const params = Object.fromEntries((new URLSearchParams(window.location.search)).entries())

	useEffect(() => {
        if(!passRegex.test(password)){
            setPassReg(true)
        }
        if(passRegex.test(password)){
            setPassReg(false)
        }
        if (password === rePassword) {
            setPassCheckError(false);
        } else {
            setPassCheckError(true)
        }
    }, [password, rePassword])

	useEffect(() => {
		if(error == '')
			switch (params.mode) {
			case "resetPassword":
				projectAuth.verifyPasswordResetCode(params.oobCode).then(() =>
					{
						setIsReset(true)
					}).catch(err => {
					})

				break;
			case "verifyEmail":
				setIsVerify(true)
				handleVerifyEmail(params.oobCode)
				break;
			default:
				setError("Error")
				break;
			}

	}, [params, User])

    const handleSubmit = (e) => {
		handleResetPassword(params.oobCode);
		history.push('/login')

    }


	function handleResetPassword(code) {
		const res = projectAuth.confirmPasswordReset(code, rePassword).then((resp) =>
			{

			}).catch(err => {
				setError(err)
			});
	}

	 function handleVerifyEmail(code) {
		
		projectAuth.applyActionCode(code).then((resp) => {
			console.log(User.uid)
			if(User != null){
				updateDocument(User.uid, {verified: true }) .then(() => {
					window.location.reload();
				})
				}
			else {
				history.push('/login')
				window.location.reload();
			}
		}).catch((err) => {
			setError(err.message)
		});
	}

    return (
		<div>
			{(error) && <p className="error"> {error}</p>}
		{(isReset) &&
			<form onSubmit={handleSubmit} className="auth-form">
			<h2>New password</h2>
			<label>
			<span>Password:</span>
			<input
		required
		type="password" 
		onChange={(e) => setPassword(e.target.value)} 
		value={password}
			/>
			</label>
			{(password && !passReg) && (<PasswordStrengthBar minLength={6} password={password} />)}
		{ (password && passReg) && <div className='error'>{"Password must be at least one uppercase, one lowercase, one special character and one number and 6 characters long."}</div>}

			<label >
			<span>Confirm Password:</span>
			<input
		required
		type="password" 
		onChange={handleCPassword} 
		value={rePassword}
			/>
			</label>
			{  (passCheckError && ( password && rePassword) ) && <div className='error'>{"Passwords do not match."}</div>}
			<button className="btn" style={{margin: "0 0 100px 0", float: "right"}} disabled={passReg}>Reset password</button>
			{error && <div className='error'>{error}</div>}
			<br></br>
		 </form>
		}

		</div>

	)
}
