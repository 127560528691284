import { useDocument } from '../../hooks/useDocument'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useState } from 'react'
import firebase from 'firebase/app'
import { useFirestore } from "../../hooks/useFirestore"
import './Profile.css'


export default function Profile() {
  const {user} = useAuthContext()
  const {deleteDocument} = useFirestore('2023/users/users')
  const { document, errors } = useDocument('2023/users/users', user.uid)
  const [err, setError] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [password, setPassword] = useState('')

  const togglePopup = () => {
    setIsOpen(!isOpen);
    setError(null)
  }

 

  const handleDelete= (e) => {
    e.preventDefault()
    //console.log(user.uid)
    const User = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      User.email, 
      password
      );
      User.reauthenticateWithCredential(credential).then(() => {

		  deleteDocument(user.uid)
		  User.delete().then(() => {
			  window.location.reload();
		  }).catch((error) => {
			  setError('An Error occured while deleting')
      });
    }).catch((error) => {
      setError('Could not reauthenticate you. Make sure you type in your password correctly!')
    });
    
   
    }

  if (errors) {
    return <div className="error">{errors}</div>
  }
  if (!document) {
    return <div className="loading">Loading...</div>
  }

  return (
    <div>
		  <div className="profile-summary">
		  <div className="desktop">
          <h2 className="page-title">Email: {document.email}</h2>
		  </div>
		  <div className="mobile">
          <h2 className="page-title">Email: {document.email}</h2>
		  </div>

        <p className="info">Field of study: {document.fieldOfStudy} </p>
        <p className="info">Hobbies: {document.hobbies} </p>

      </div>
      <br></br>
      <button className="btn-delete" onClick={togglePopup}>Delete account</button>
      {isOpen && (<form onSubmit={handleDelete} className="auth-form">
      <h2>Please provide your password</h2>
      <label>
      <span>Password:</span>
        <input
          required
          type="password" 
          onChange={(e) => setPassword(e.target.value)} 
          value={password}
        />
        </label>

        <button className="btn-delete">Delete</button>
 
        <button className="btn-cancel-grey" onClick={togglePopup}>Cancel</button>
        </form>)
        }
        {err && (<p className='error'>{err}</p>)}
	</div>
  )
}
